// New ("V2") User Setting model
// Used for a singular user setting with a unique key.
// Works in conjunction with the UserSettings.collections
define(['models/app/BasicModel', 'backbone', 'CC'], function(BasicModel, Backbone, CC) {
    const userSetting = BasicModel.extend({
        idAttribute: 'key',
        urlRoot: function() {
            if (typeof CC === 'undefined') {
                CC = require('CC');
            }
            return `${CC.apiUrl}/users/activeUser/setting`;
        },

        // value sent back to API must be JSON encoded.
        toJSON: function() {
            return {
                key: this.get('key'),
                value: JSON.stringify(this.get('value'))
            };
        }
    });

    return userSetting;
});
