// BasicModel
define(function(require) {
    const $ = require('jquery');
    const CC = require('CC');
    const Backbone = require('backbone');
    const _ = require('underscore');
    require('backbone-nested-models');
    const basicModel = Backbone.Model.extend({
        defaultParams: {},

        constructor: function(attributes, options) {
            // need a clone of the class defaults to ensure they're not overwritten.
            const defaultParams = _.extend({}, this.defaultParams);
            options = _.extend({}, { params: defaultParams }, options);
            Backbone.Model.apply(this, arguments);
            this.params = options.params;
            this.loading = false;
            this.loaded = false;
        },

        // Many models just did this. Override if you need more.
        initialize: function(attributes, options) {
            this.options = _.extend({}, this.defaults, this.options);
        },

        url: function() {
            let myUrl = Backbone.Model.prototype.url.apply(this, arguments);
            myUrl = this.fillInPlaceholders(myUrl);
            return myUrl;
        },

        fillInPlaceholders: function(myUrl) {
            // find the location of the 1st ?
            // go to ? or end of string
            const usedParams = {};
            for (const name in this.params) {
                usedParams[name] = false;
            }
            let pos = myUrl.indexOf('?');
            if (pos === -1) {
                pos = myUrl.length;
            }
            const subUrl = myUrl.substr(0, pos);
            if (subUrl.indexOf('{') !== -1) {
                // look for any {} placeholders
                const pattern = /\{[a-zA-Z_$][0-9a-zA-Z_$]*\}/g;
                const match = subUrl.match(pattern);

                // grab the placeholder string out of it
                if (match) {
                    for (let i = 0; i < match.length; i++) {
                        const placeholder = match[i];
                        const paramName = placeholder.substring(1, placeholder.length - 1);
                        // looking for replacements
                        if (typeof this.params[paramName] !== 'undefined') {
                            // explicit params first
                            myUrl = myUrl.replace(placeholder, this.params[paramName]);
                            usedParams[paramName] = true;
                        } else {
                            // fall back to model attributes
                            const val = this.get(paramName);
                            if (typeof val !== 'undefined') {
                                myUrl = myUrl.replace(placeholder, val);
                            }
                        }
                    }
                }
            }
            // build list of params that are not placeholders
            const queryParams = {};
            for (const paramName in usedParams) {
                if (usedParams[paramName] === false) {
                    queryParams[paramName] = this.params[paramName];
                }
            }
            // any param not used as a placeholder becomes a query string parameter
            myUrl = CC.utils.addQueryParams(myUrl, queryParams);
            return myUrl;
        },

        setParam: function(param, value) {
            this.params[param] = value;
            return this;
        },

        setParams: function(params) {
            this.params = _.extend(this.params, params);
            return this;
        },

        unsetParam: function(param) {
            if (typeof this.params[param] !== 'undefined') {
                delete this.params[param];
            }
            return this;
        },

        getParam: function(param) {
            let value;
            if (typeof this.params[param] !== 'undefined') {
                value = this.params[param];
            }
            return value;
        },

        getParams: function() {
            return this.params;
        },

        clear: function(options) {
            if (typeof options === 'undefined' || typeof options.keep_params === 'undefined' || !options.keep_params) {
                this.params = {};
            }
            Backbone.Model.prototype.clear.apply(this, arguments);
            return this;
        },

        // Wrapper for parent fetch call.
        fetch: function(options) {
            this.loading = true;
            this.loaded = false;
            this.trigger('loading');
            const self = this;
            const request = Backbone.Model.prototype.fetch.apply(this, arguments);
            request.done(function(data) {
                self.loading = false;
                self.loaded = true;
                self.trigger('loaded');
            });
            return request;
        },

        isLoading: function() {
            return this.loading;
        },

        isLoaded: function() {
            return this.loaded;
        },

        // Use this if you set a model's attributes directly instead of fetching
        setLoaded: function(loaded) {
            this.loaded = loaded === true;
            return this;
        },

        toString: function() {
            return `${this.get('name')} [${this.get('id')}]`;
        },

        // Borrowed from backbone-validation, used as an equivalent for required: true
        hasValue: function(value) {
            return !(
                _.isNull(value) ||
                _.isUndefined(value) ||
                (_.isString(value) && $.trim(value) === '') ||
                (_.isArray(value) && _.isEmpty(value))
            );
        },

        /**
         * Builds an array of patch operations
         * @param  {[Array]} operations [{ operation: "replace|add|remove", attribute: "attributeName"}]
         * @return {[Array]}            [operations]
         */
        buildPatchOperations: function(operations) {
            const self = this;
            return _.map(operations, function(operation) {
                return {
                    op: operation.operation,
                    path: `/${operation.attribute}`,
                    value: self.get(operation.attribute)
                };
            });
        },

        /**
         * Send a patch request to the server
         * @param  {Array|String} operation An array of patch operations OR an operation
         *      [{ operation: "replace|add|remove", attribute: "attributeName"}]
         * @param  {Object|String|} attribute An options hash OR the attribute for the above operation
         * @param  {Object} options   undefined OR an options hash
         * @return {XHR}           The patch command's xhr
         */
        patch: function(operation, attribute, options) {
            let patchOperations;
            if (_.isArray(operation)) {
                patchOperations = this.buildPatchOperations(operation);
                options = attribute;
            } else {
                patchOperations = this.buildPatchOperations([
                    {
                        operation: operation,
                        attribute: attribute
                    }
                ]);
            }

            const syncOptions = _.extend(
                {
                    attrs: patchOperations
                },
                options
            );
            return this.sync('patch', this, syncOptions);
        },

        sync: function(method, model, options) {
            if (method == 'patch') {
                // Default options, unless specified.
                _.defaults(options || (options = {}), {
                    emulateHTTP: Backbone.emulateHTTP,
                    emulateJSON: Backbone.emulateJSON
                });

                // Default JSON-request options.
                const params = {
                    type: 'PATCH',
                    dataType: 'json',
                    contentType: 'application/json-patch+json',
                    processData: false,
                    url: _.result(model, 'url'),
                    data: JSON.stringify(options.attrs)
                };

                if (!params.url) {
                    throw new Error('A "url" property or function must be specified');
                }

                const error = options.error;
                options.error = function(xhr, textStatus, errorThrown) {
                    options.textStatus = textStatus;
                    options.errorThrown = errorThrown;
                    if (error) error.call(options.context, xhr, textStatus, errorThrown);
                };

                // Make the request, allowing the user to override any Ajax options.
                const xhr = (options.xhr = Backbone.ajax(_.extend(params, options)));
                model.trigger('request', model, xhr, options);
                return xhr;
            } else {
                return Backbone.sync.apply(this, arguments);
            }
        }
    });

    return basicModel;
});
