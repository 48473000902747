define([
    'backbone',
    'CC'
], 
function(Backbone,CC) {
    var userOrgsCollection = Backbone.Collection.extend({
        url: function() {
            return CC.apiUrl + '/clients/assigned';
        },
    
        parse: function(response) {
            return response.clients;
        }
    });
  
    return userOrgsCollection;
});