define(function(require) {
    const featureModel = require('models/security/Feature.model');
    const _ = require('underscore');
    const CC = require('CC');
    const Backbone = require('backbone');

    const featuresCollection = Backbone.Collection.extend({
        model: featureModel,
        options: {},

        url: function() {
            return `${CC.apiUrl}/security/features`;
        },

        isEnabled: function(featureName) {
            return typeof this.findWhere({ name: featureName }) != 'undefined';
        },

        getIdForFeatureName: function(featureName) {
            const feature = this.findWhere({ name: featureName });
            if (typeof feature != 'undefined') {
                return feature.get('featureId');
            } else {
                return null;
            }
        }
    });

    return featuresCollection;
});
