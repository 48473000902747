define(function(require) {
    const CC = require('CC');
    const BasicModel = require('models/app/BasicModel');
    const Feature = BasicModel.extend(
        {
            idAttribute: 'featureId',

            urlRoot: function() {
                return `${CC.apiUrl}/security/features`;
            },

            toJSON: function(options) {
                const modelJson = BasicModel.prototype.toJSON.apply(this, arguments);
                delete modelJson.enabled;
                return modelJson;
            }
        },
        {
            RolloutStatusOptions: [
                {
                    id: 'PREVIEW',
                    name: 'Preview',
                    title: 'Available to Lotame Employees only view <code>with_feature=<featureName></code>'
                },
                {
                    id: 'ALPHA',
                    name: 'Alpha',
                    title: 'Available to users who have "BETA UI Features" turned on'
                },
                {
                    id: 'BETA',
                    name: 'Beta',
                    title: 'Talk to Product First! Enabled when turned on for a client on their Manage Features Tab'
                },
                {
                    id: 'GA',
                    name: 'GA',
                    title: 'Enabled when turned on for a client on their Manage Features Tab'
                },
                {
                    id: 'INTEGRATED',
                    name: 'Integrated',
                    title: 'Available to all client ids. Feature is ready to be removed'
                }
            ]
        }
    );
    return Feature;
});
