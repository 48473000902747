define(function(require) {
    const UserSetting = require('models/security/UserSetting');
    const CC = require('CC');
    const _ = require('underscore');

    const UserSettingUtil = {
        EXCESS_MODE: {
            TRUNCATE: 'TRUNCATE',
            ERROR: 'ERROR'
        },
        isUserSettingsEnabled: function() {
            const isEnabled = typeof CC.SessionData.userSettingsV2 !== 'undefined';
            return isEnabled;
        },

        /**
         *
         * @param {String} key
         * @param {String} value
         * @returns {JQuery.jqXHR}
         */
        saveUserSetting: function(key, value) {
            if (UserSettingUtil.isUserSettingsEnabled()) {
                let my_model = CC.SessionData.userSettingsV2.findWhere({ key: key });
                if (typeof my_model === 'undefined') {
                    my_model = new UserSetting({
                        key: key
                    });
                    CC.SessionData.userSettingsV2.add(my_model);
                }
                // Always call set() on existing model so that hasChanged() returns true later
                // - because isNew() always true on models without id attribute
                my_model.set('value', value);
                return my_model.save();
            }
            return false;
        },

        /**
         *
         * @param {String} keyToFind
         * @returns {String}
         */
        getUserSetting: function(keyToFind) {
            if (UserSettingUtil.isUserSettingsEnabled()) {
                const userSetting = CC.SessionData.userSettingsV2.findWhere({ key: keyToFind });
                if (!_.isUndefined(userSetting)) {
                    return userSetting.get('value');
                }
            }
            return undefined;
        },

        /**
         *
         * @param {String} keyToFind
         * @returns {jqXHR|false}
         */
        deleteUserSetting: function(keyToFind) {
            if (UserSettingUtil.isUserSettingsEnabled()) {
                const userSetting = CC.SessionData.userSettingsV2.findWhere({ key: keyToFind });
                if (!_.isUndefined(userSetting)) {
                    return userSetting.destroy({ wait: true });
                }
            }
            return false;
        },

        /**
         *
         * @param {{key: string, limit: number, item: string, excessMode: (TRUNCATE|ERROR)}} opts
         * @returns {JQuery.jqXHR}
         */
        addToUserSetting: function({ key, limit, item, excessMode }) {
            // Get the current list
            const currentList = UserSettingUtil.getUserSetting(key);

            // Add to the current list
            let newList = new Array();
            if (!_.isUndefined(currentList) && !_.isEmpty(currentList)) {
                switch (excessMode) {
                    case UserSettingUtil.EXCESS_MODE.TRUNCATE:
                        currentList.unshift(item);
                        newList = currentList.slice(0, limit);
                        break;
                    case UserSettingUtil.EXCESS_MODE.ERROR:
                    default:
                        if (currentList.length < limit) {
                            currentList.unshift(item);
                            newList = currentList;
                        } else {
                            throw new Error(`Max capacity of ${key} has been reached with ${limit}`);
                        }
                }
            } else {
                newList = [item];
            }

            // Save the updated list
            return UserSettingUtil.saveUserSetting(key, newList);
        },

        /**
         *
         * @param {String} key
         * @param {String} item
         * @returns {Jquery.jqXHR}
         */
        removeFromUserSetting: function(key, item) {
            // Get the current list
            const currentList = UserSettingUtil.getUserSetting(key);

            // Remove from the list
            const index = currentList.indexOf(item);
            if (index !== -1) {
                currentList.splice(index, 1);
            }

            // Save the updated list
            return UserSettingUtil.saveUserSetting(key, currentList);
        }
    };
    return UserSettingUtil;
});
