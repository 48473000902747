define(function(require) {
    const CC = require('CC');
    const _ = require('underscore');
    const BaseCollection = require('models/Base.collection');
    const ClientCapabilityModel = require('models/client/ClientCapability.model');
    const ClientCapabilities = BaseCollection.extend({
        model: ClientCapabilityModel,

        urlRoot: function() {
            return `${CC.apiUrl}/clients/{clientId}/capabilities`;
        },

        parseRecords: function(response) {
            let records = [];
            // Because we are looking for a particular capability by name and
            // not grouping, we flatten the results here for easier access.
            _.each(Object.keys(response), key => {
                records = records.concat(response[key]);
            });
            return records;
        },

        isEnabled(capabilityName) {
            const capability = this.findWhere({
                name: capabilityName
            });
            return capability && capability.isEnabled();
        }
    });
    return ClientCapabilities;
});
