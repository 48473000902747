define(function(require) {
    const CC = require('CC');
    const BasicModel = require('models/app/BasicModel');
    const _ = require('underscore');
    const Backbone = require('backbone');
    const XRegExp = require('xregexp');
    const ClientFlags = BasicModel.extend({
        defaults: {
            maxProfileAudiences: 20,
            overlapType: 'data',
            automatedBillingChannelsRelationship: 'NONE',
            apiTokenTimeoutDays: 7
        },
        url: function() {
            //API link for client flags
            const urlRoot = `${CC.apiUrl}/clients/{clientId}/settings/flags`;
            return this.fillInPlaceholders(urlRoot);
        },

        isAutomatedBillingChannelsAllowed: function() {
            return this.get('automatedBillingChannelsRelationship') != 'NONE';
        },

        isAutomatedBillingChannelsLDX: function() {
            return this.get('automatedBillingChannelsRelationship') == 'LDX';
        },

        isAutomatedBillingChannelsSeller: function() {
            return this.get('automatedBillingChannelsRelationship') == 'SELLER';
        },

        canViewAutomatedBillingChannelsPayouts: function() {
            const relationship = this.get('automatedBillingChannelsRelationship');
            return relationship == 'SELLER' || relationship == 'LDX';
        },

        isAutomatedBillingChannelsBuyer: function() {
            return this.get('automatedBillingChannelsRelationship') == 'BUYER';
        },

        // Used to identify the use case for an AutomatedBillingChannels Buyer that doesn't have CPM constraints.
        isBuyerWithoutMinMax: function() {
            return (
                this.get('automatedBillingChannelsRelationship') == 'BUYER' &&
                !this.hasValue(this.getAutomatedBillingChannelsMinimumCpm()) &&
                !this.hasValue(this.getAutomatedBillingChannelsMaximumCpm())
            );
        },

        setClientId: function(clientId) {
            this.set('clientId', clientId);
        },

        originalMaxProfileAudiences: 0,
        validateMaxProfileAudiences: function(value, attr, computedState) {
            /*
            DESK-15346
            Because product has asked for a limit of 50 benchmark audiences, we enforce that here.
            However, CS wants to reserve the right to bypass this restriction via direct DB push of the value,
            so we ONLY enforce the restriction if they're editing THIS value.  Engineering feels that this
            IS going to cause problems down the road (for example, when someone tries to edit the 80 they set
            by DB push, and discovers that they accidentally typed "7" on the way to "75", and the system accepted the
            new value of "7" but now rejects "75".  If you're reading this because of that, please take this
            opportunity to reconsider allowing internals to bypass restrictions imposed on them by making direct DB
            pushes.
            */
            if (value != this.originalMaxProfileAudiences) {
                if (value < 0) {
                    return 'Max Benchmark Audiences must be greater than or equal to 0.';
                } else if (value > 50) {
                    return 'Max Benchmark Audiences must be 50 or less.';
                }
            }
            // If we didn't object to any of that, just don't return anything.
        },

        getAutomatedBillingChannelsMaximumCpm: function() {
            return this.get('automatedBillingChannelsMaximumCpm');
        },
        getAutomatedBillingChannelsMinimumCpm: function() {
            return this.get('automatedBillingChannelsMinimumCpm');
        },

        validation: {
            maxProfileAudiences: 'validateMaxProfileAudiences',
            automatedBillingChannelsMinimumCpm: {
                fn: function(value, attr, computedState) {
                    const strVal = String(value);
                    let error = false;
                    const relationship = this.get('automatedBillingChannelsRelationship');
                    if (relationship != 'NONE') {
                        const min = parseFloat(strVal);

                        if (relationship == 'BUYER') {
                            const max = parseFloat(this.getAutomatedBillingChannelsMaximumCpm());
                            if (_.isNumber(max) && !_.isNaN(max) && max <= min) {
                                error = 'Must be less than Maximum CPM.';
                            }
                        }

                        // If you add a function, Validation ignores the pattern attribute
                        if (
                            !this.isBuyerWithoutMinMax() &&
                            (!strVal.match(Backbone.Validation.patterns.number) || min < 0)
                        ) {
                            error = 'Must be a number greater than or equal to zero';
                            error += relationship == 'BUYER' ? ', or both min & max must be blank.' : '.';
                        }
                    }
                    return error;
                },
                required: function(value, attr, computedState) {
                    return computedState.automatedBillingChannelsRelationship != 'NONE';
                }
            },

            automatedBillingChannelsMaximumCpm: {
                fn: function(value, attr, computedState) {
                    const strVal = String(value);
                    let error = false;
                    if (this.isAutomatedBillingChannelsBuyer() && !this.isBuyerWithoutMinMax()) {
                        const min = parseFloat(this.getAutomatedBillingChannelsMinimumCpm());
                        const max = parseFloat(strVal);
                        if (!strVal.match(Backbone.Validation.patterns.number) || max <= 0) {
                            const relationship = this.get('automatedBillingChannelsRelationship');
                            error = 'Must be a number greater than zero';
                            error += relationship == 'BUYER' ? ', or both min & max must be blank.' : '.';
                        } else if (_.isNumber(min) && !_.isNaN(min) && max <= min) {
                            error = 'Must be greater than Minimum CPM.';
                        }
                    }
                    return error;
                },
                required: function(value, attr, computedState) {
                    return computedState.automatedBillingChannelsRelationship == 'BUYER';
                }
            },
            automatedBillingChannelsMinPercentSpend: {
                fn: function(value, attr, computedState) {
                    const strVal = String(value);
                    let error = false;
                    if (this.hasValue(value) && this.isAutomatedBillingChannelsBuyer()) {
                        if (strVal.match(Backbone.Validation.patterns.digits)) {
                            if (value >= 100) {
                                error = 'Must be less than 100';
                            } else if (value < 1) {
                                error = 'Must be greater than 0';
                            }
                        } else {
                            error = 'Must be a whole number';
                        }
                        return error;
                    }
                }
            },

            whiteLabelKey: {
                fn: function(value, attr, computed) {
                    if (this.hasValue(value)) {
                        const whiteLabelKey = value.trim();
                        const anyWhitespace = /\s+/;
                        const MAX_KEY_LENGTH = 50;
                        const anythingButPunctuation = XRegExp('^[\\pL\\pM\\pN-]+$');

                        // whiteLabelKey is required, can't be whitespace, must be <= 50 characters, can't contain special characters
                        if (whiteLabelKey.length > MAX_KEY_LENGTH) {
                            return `whiteLabelKey cannot be longer than ${MAX_KEY_LENGTH} characters.`;
                        } else if (whiteLabelKey.match(anyWhitespace)) {
                            return 'whiteLabelKey cannot contain any whitespace.';
                        } else if (!anythingButPunctuation.test(whiteLabelKey)) {
                            return (
                                'whiteLabelKey cannot contain punctuation or zero-width space characters. ' +
                                'Letters(any language), numbers, and the character "-" are allowed.'
                            );
                        }
                    }
                }
            },

            logoFileName: {
                required: false,
                minLength: 5,
                maxLength: 50
            },

            apiTokenTimeoutDays: {
                required: true,
                range: [1, 31]
            }
        },

        labels: {
            maxProfileAudiences: 'Benchmark Audience Slots'
        },

        fetch: function(options) {
            // First do the standard fetch.
            const fetchResult = BasicModel.prototype.fetch.call(this, options);

            // Then store off the original max benchmark audiences.
            this.originalMaxProfileAudiences = this.get('maxProfileAudiences');

            // Then make the standard return.
            return fetchResult;
        }
    });

    _.extend(ClientFlags.prototype, Backbone.Validation.mixin);
    return ClientFlags;
});
