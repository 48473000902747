//
// A standard base view that handles rendering alerts. All views
// should inherit from this view
//
define(function(require) {
    const AlertTemplate = require('app/Alert.template.html');
    const NoDataTemplate = require('app/NoData.template.html');
    const CC = require('CC');
    const _ = require('underscore');
    const $ = require('jquery');
    const Backbone = require('backbone');
    const Stickit = require('stickit');
    const { authorize } = require('utils/authorization');

    const baseView = Backbone.View.extend(
        {
            alertProperties: {
                el: '#alert-holder',
                dismissible: false,
                alertType: 'danger',
                message: 'Something went wrong',
                delayMS: 0,
                animationMS: 500,
                intro: '',
                isAppended: false,
                icon: false,
                forceSuccessRenderToElement: false,
                isPushAlert: false,
                alertStyle: 'regular' // regular|slim
            },

            validation: [
                // Field Validation
                // {
                //     name: {String},      # Required
                //     required: {Boolean}  # Defaults to false
                // }
            ],

            alertTemplate: _.template(AlertTemplate),
            defaultLoader:
                '<div class="default-loader"><div class="bouncing-loader"><div></div><div></div><div></div></div></div>',
            smallLoader:
                '<div class="text-center base--small-loader"><img src="/web/images/loading_32x32.gif" /></div>',
            bouncingLoader: '<div class="bouncing-loader"><div></div><div></div><div></div></div>',
            alertHolder: '<div id="alert-holder" /></div>',

            //
            // Set up the defaults
            //
            constructor: function(options) {
                this.options = options;
                this.Stickit = Stickit;
                //
                // Deep-Copy the objects
                //
                // Option Priority:
                // Parameters
                // Inheriting View Defaults
                // Base View Defaults
                //
                $.extend(true, this, _.result(this, 'defaults'), options);

                Backbone.View.apply(this, arguments);
            },

            render: function() {
                this.$el.html(this.defaultLoader);

                return this;
            },

            /**
             *
             * @param {Number} [timeoutMS] -  Milliseconds to wait before rendering the loader
             * @param {string} [viewEl] - defaults to the main element of the view
             */
            renderMainLoader: function(timeoutMS = 1000, viewEl = undefined) {
                const $element = _.isString(viewEl) ? this.$(viewEl) : this.$el;
                this.clearMainLoaderTimer();
                this.mainLoaderTimeout = setTimeout(() => {
                    if ($element.length !== 0) {
                        $element.html(this.defaultLoader);
                    }
                }, timeoutMS);
            },

            clearMainLoaderTimer: function() {
                clearTimeout(this.mainLoaderTimeout);
            },

            renderError: function(alertProperties) {
                this.$el.html(this.alertHolder);
                this.renderAlert(alertProperties);

                return this;
            },

            renderNoData: function(options) {
                const opts = _.extend(
                    {},
                    {
                        el: '',
                        message: 'No Data Found',
                        reasons: [],
                        showBoxes: true,
                        alwaysShowSummary: false,
                        imageUrl: '/web/images/svgs/EmptyState.svg',
                        summaryMessage: _.template(
                            'For more assistance, please contact <a href="<%- helpUrl %>">support</a>.'
                        )({
                            helpUrl: CC.getConfigSetting('HelpUrl')
                        }),
                        shouldScrollIntoView: true
                    },
                    options
                );

                this.$(opts.el).html(_.template(NoDataTemplate)(opts));

                if (opts.shouldScrollIntoView) {
                    if (!$(opts.el)[0]) {
                        throw new Error('Error finding element for the no data message');
                    }
                    // Make sure the error is visible
                    $(opts.el)[0].scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    });
                }

                return this;
            },

            validateProperties: function() {
                _.each(_.result(this, 'validation'), item => {
                    if (_.isBoolean(item.required) && item.required) {
                        if (!_.isString(item.name)) {
                            throw new Error(`${item.name} should be a string`);
                        }
                        if (!this.hasValue(this[item.name])) {
                            throw new Error(`${item.name} is required`);
                        }
                    }
                });
            },

            /**
             * Borrowed from Backbone.validation
             * @param  {?} value The item to check
             * @return {Boolean} Whether or not the parameter has a value
             */
            hasValue: function(value) {
                return CC.utils.hasValue(value);
            },

            //
            // Render a simple alert
            //
            // @alertProperties
            //
            renderAlert: function(alertProperties) {
                const props = _.extend({}, this.alertProperties, alertProperties);

                const asPushAlert =
                    alertProperties.isPushAlert ||
                    (props.alertType == 'success' && !alertProperties.forceSuccessRenderToElement);

                if (asPushAlert) {
                    CC.Events.trigger('Page:PushAlert', props);
                } else {
                    props.iconClass = this.getIconClass(props);
                    props.showIntro = !CC.utils.isEmpty(props.intro);

                    if (props.isAppended) {
                        this.$(props.el).append(this.alertTemplate(props));
                    } else {
                        this.$(props.el).html(this.alertTemplate(props));
                    }

                    if (props.delayMS !== 0) {
                        this.$(`${props.el} .alert`)
                            .fadeTo(props.delayMS, props.animationMS)
                            .slideUp(props.animationMS, () => {
                                this.$(`${props.el} .alert`).alert('close');
                            });
                    }
                }
            },

            //
            // Clear an alert
            //
            // @alertEl The element to clear. Will fall back to property source one
            clearAlert: function(alertEl) {
                this.$(alertEl || this.alertProperties.el).empty();
            },

            // see http://ianstormtaylor.com/assigning-backbone-subviews-made-even-cleaner/  for what this is doing.
            // note: this has been modified slightly for Lotame-specific behavior
            assign: function(selector, view, renderOptions) {
                let selectors;
                if (_.isObject(selector)) {
                    selectors = selector;
                } else {
                    selectors = {};
                    selectors[selector] = view;
                }
                if (!selectors) {
                    return;
                }
                _.each(
                    selectors,
                    function(view, selector) {
                        view.setElement(this.$(selector)).render(renderOptions);
                        view.viewSelector = selector;

                        // Save a reference to each subview so we can clean them up later
                        this._addSubView(view);
                    },
                    this
                );
            },

            append: function(selector, view, renderOptions) {
                const self = this; // Uses both self and this
                let selectors;
                if (_.isObject(selector)) {
                    selectors = selector;
                } else {
                    selectors = {};
                    selectors[selector] = view;
                }
                if (!selectors) {
                    return;
                }
                _.each(
                    selectors,
                    function(view, selector) {
                        view.render(renderOptions);
                        self.$(selector).append(view.el);
                        view.setElement(view.el);
                        view.viewSelector = view.el;

                        // Save a reference to each subview so we can clean them up later
                        this._addSubView(view);
                    },
                    this
                );
            },

            //
            // Add a subview and track when it closes
            //
            // @view THe view that is being added as a sub view
            //
            _addSubView: function(view) {
                if (_.isUndefined(this._subViews)) {
                    this._subViews = [];
                }

                // Don't Add duplicates
                if (this.getSubView(view.viewSelector) === undefined) {
                    this._subViews.push(view);

                    // Listen as a parent for when a child is closing
                    this.listenToOnce(view, 'close', this._removeSubView, this);
                }
            },

            //
            // Remove a tracked subview
            //
            // @subview The view object that was added to the current view
            _removeSubView: function(subview) {
                // Remove this subview from the parent's list of subviews
                const index = this._subViews
                    .map(function(v) {
                        return v.cid;
                    })
                    .indexOf(subview.cid);
                this._subViews.splice(index, 1);
            },

            getSubView: function(viewEl) {
                return _.findWhere(this._subViews, {
                    viewSelector: viewEl
                });
            },

            enableElement: function(element) {
                if (!this.$el) {
                    // Abort
                    return;
                }
                return this.$(element)
                    .prop('disabled', false)
                    .removeClass('disabled');
            },

            enableGlobalElement: function(element) {
                return $(element)
                    .prop('disabled', false)
                    .removeClass('disabled');
            },

            disableElement: function(element) {
                if (!this.$el) {
                    // Abort
                    return;
                }
                return this.$(element)
                    .prop('disabled', true)
                    .addClass('disabled');
            },

            disableGlobalElement: function(element) {
                return $(element)
                    .prop('disabled', true)
                    .addClass('disabled');
            },

            enableActiveElement: function(element) {
                return this.$(element)
                    .prop('disabled', false)
                    .removeClass('disabled')
                    .addClass('active');
            },

            disableActiveElement: function(element) {
                return this.$(element)
                    .prop('disabled', true)
                    .removeClass('active')
                    .addClass('disabled');
            },

            close: function(options) {
                const defaultOptions = {
                    // eslint-disable-next-line camelcase
                    keep_element: false // whether or not to nuke the view.el
                };
                options = _.extend(defaultOptions, options);

                // Let any parent know that we are closing
                this.trigger('close', this);

                // Unbind any validation
                Backbone.Validation.unbind(this);

                // keep the view.el
                if (options.keep_element) {
                    // remove all events stored in view._listeners (added by listenTo method in view)
                    this.stopListening();
                    // removes all backbone delegated events
                    this.undelegateEvents();
                    // remove any events bound to view.el outside of backbone
                    this.$el.off();
                    // empty view.el contents
                    this.$el.empty();
                } else {
                    // nuke the view.el
                    // check for $el b/c some modals self-remove it
                    if (typeof this.$el !== 'undefined') {
                        this.remove();
                    }
                    delete this.$el;
                    delete this.el;
                    delete this.viewSelector;
                }

                this.unbind();

                // Close all subforms
                if (!_.isUndefined(this._subViews)) {
                    _.each(this._subViews, function(childView) {
                        if (childView.close) {
                            childView.close();
                        }
                    });
                    this._subViews.length = 0;
                }

                if (this.onClose) {
                    this.onClose();
                }
            },

            //
            // Create and render a view in the dom
            //
            // @viewEl The element in the dom to assign this view
            // @ViewClass The View class to create
            // @viewOptions The options to be passed to ViewClass initialize
            //
            // @Return A reference to the newly create view
            createAndRenderSubView: function(viewEl, ViewClass, viewOptions) {
                // First, close any existing view
                let currentView = this.getSubView(viewEl);
                if (!_.isUndefined(currentView)) {
                    currentView.close({
                        // eslint-disable-next-line camelcase
                        keep_element: true
                    });
                }

                // Create a new instance of the view
                viewOptions.viewEl = viewEl;
                if (ViewClass.default) {
                    currentView = new ViewClass.default(viewOptions);
                } else {
                    currentView = new ViewClass(viewOptions);
                }

                // Add the view to the DOM
                if (viewOptions && viewOptions.append) {
                    this.append(viewEl, currentView);
                } else {
                    this.assign(viewEl, currentView);
                }
                return currentView;
            },

            //
            // Render the view, creating it if necessary
            //
            // @viewEl The element in the dom to assign this view
            // @ViewClass The View class to create
            // @viewOptions The options to be passed to ViewClass initialize
            //
            // @Return A reference to the view
            renderSubView: function(viewEl, ViewClass, viewOptions) {
                // Only create the view if it doesn't already exist
                let currentView = this.getSubView(viewEl);
                viewOptions.viewEl = viewEl;
                if (_.isUndefined(currentView)) {
                    // Create a new instance of the view
                    if (ViewClass.default) {
                        currentView = new ViewClass.default(viewOptions);
                    } else {
                        currentView = new ViewClass(viewOptions);
                    }
                }

                // Add the view to the DOM
                this.assign(viewEl, currentView);

                return currentView;
            },

            // utility to test for access by any combination of features and permissions.
            // By default, failing access means a redirect to the dashboard. You can specify
            // a different location or false to skip the redirect. Features and permissions
            // can be arrays or single strings.
            checkForAccess: function(features, permissions, destination) {
                let hasAccess = true;
                if (typeof destination === 'undefined') {
                    destination = '/';
                }

                if (typeof features !== 'undefined') {
                    if (!_.isArray(features)) {
                        features = [features];
                    }
                    for (let i = 0; i < features.length; i++) {
                        const feature = features[i];
                        if (!authorize(feature)) {
                            hasAccess = false;
                            break;
                        }
                    }
                }

                if (hasAccess && typeof permissions !== 'undefined') {
                    if (!_.isArray(permissions)) {
                        permissions = [permissions];
                    }

                    for (let x = 0; x < permissions.length; x++) {
                        const permission = permissions[x];
                        if (!CC.authorizeByPermission(permission)) {
                            hasAccess = false;
                            break;
                        }
                    }
                }

                if (!hasAccess && destination !== false) {
                    CC.page.redirect(destination);
                }

                return hasAccess;
            },

            getIconClass: function(props) {
                let iconClass = '';
                if (props.icon) {
                    switch (props.alertType) {
                        case 'info':
                            iconClass = 'info-sign';
                            break;

                        case 'warning':
                            iconClass = 'exclamation-sign';
                            break;

                        case 'danger':
                            iconClass = 'remove-circle';
                            break;

                        case 'success':
                            iconClass = 'ok';
                            break;
                    }
                }
                return iconClass;
            },

            /**
             * Fetch several models/collections and call a function when they are completed
             * @param  {array of models/collections} collections       The entities to be fetched
             * @param  {function} completionFn The function to call when the fetches have completed
             * @return {this}              For chaining
             */
            fetchAndWait: function(collections, completionFn) {
                const errors = [];
                const onComplete = _.after(collections.length, (data, textStatus, jqXHR) => {
                    completionFn.apply(this, [data, textStatus, jqXHR, errors]);
                });
                _.each(collections, function(collection, i) {
                    collection
                        .fetch()
                        .fail(function(xhr, textStatus, errorThrown) {
                            errors.push({
                                xhr: xhr,
                                textStatus: textStatus,
                                errorThrown: errorThrown,
                                collection: collection,
                                i: i
                            });
                        })
                        .always(onComplete); //  data|jqXHR, textStatus, jqXHR|errorThrown
                });

                return this;
            },

            convertColorVariableToHex: function(colors) {
                const rootElement =
                    this.$el && this.$el.length > 0 && this.$el.is(':visible') ? this.$el[0] : document.body;
                const style = getComputedStyle(rootElement);
                let colorArray = colors;
                if (!_.isArray(colors)) {
                    colorArray = [colorArray];
                }
                const results = _.map(colorArray, function(color) {
                    if (color.indexOf('--') !== -1) {
                        return style.getPropertyValue(color).trim();
                    }
                    return color;
                });

                if (!_.isArray(colors)) {
                    return this.hasValue(results) && this.hasValue(results[0]) ? results[0].trim() : '';
                }
                return results;
            },

            getCSSVariable: function(name) {
                const rootElement = this.$el && this.$el.length > 0 ? this.$el[0] : document.body;
                const style = getComputedStyle(rootElement);
                return style.getPropertyValue(name).trim();
            }
        },
        {
            Time: {
                THREE_SECONDS_AS_MS: 3 * 1000,
                EIGHT_SECONDS_AS_MS: 8 * 1000,
                TEN_SECONDS_AS_MS: 10 * 1000
            }
        }
    );
    return baseView;
});
