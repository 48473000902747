define([
  'backbone'
], 
function (Backbone) {
  // override Backbone.sync with cache busting
  Backbone.ajax = function() {
    if (arguments[0].url) {
      // generate query parameter separator
      var separator = ( arguments[0].url.indexOf('?') == -1 ) ? '?' : '&';
      // generate cache bust parameter
      var cacheBust = '_=' + (new Date).valueOf();
      // append to url
      arguments[0].url = arguments[0].url + separator + cacheBust;
    }
    // call backbone's jquery ajax with updated url
    return Backbone.$.ajax.apply(Backbone.$, arguments);
  };
  return Backbone;
});