//  Author:         Andrew Conant
//  Description:    Retrieves user info from 'activeUser' api endpoint for reuse by other application modules
define(function(require) {
    var CC = require('CC');
    var BasicModel = require('models/app/BasicModel');
    var userInfoModel = BasicModel.extend({
        url: function() {
            var my_url = CC.apiUrl + '/users/activeUser';
            return my_url;
        },

        hasDataOverride: function() {
            return CC.SessionData.userInfo.get('hasDataOverride');
        }
    });

      
    return userInfoModel;
});