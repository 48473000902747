define(function(require) {
    const CC = require('CC');
    const BasicModel = require('models/app/BasicModel');
    const Backbone = require('backbone');
    const _ = require('underscore');

    const client = BasicModel.extend({
        defaults: {
            userLimit: 250,
            ClientRelationship: []
        },

        validation: {
            maxBeacons: function(value, attr, computedState) {
                if (value) {
                    if (!value.match(Backbone.Validation.patterns.digits)) {
                        return 'Must be a positive whole number OR empty to use the global default';
                    }
                }
            },
            userLimit: {
                pattern: 'digits',
                min: 0
            },
            name: {
                required: true,
                maxLength: 45
            },
            description: {
                required: false,
                maxLength: 255
            }
        },

        urlRoot: function() {
            const url_root = `${CC.apiUrl}/clients`;
            return url_root;
        },

        parse: function(response) {
            if (response) {
                response.calculatedBeacons = response.maxBeacons || response.systemDefaultMaxBeacons;
                if (response.ClientType && response.ClientType.type) {
                    response.type = response.ClientType.type;
                }
                if (response.billingOwner) {
                    response.billingOwnerClientId = response.billingOwner.clientId;
                    response.billingOwnerClientName = response.billingOwner.clientName;
                    if (response.billingOwnerClientId == response.id) {
                        response.billingOwnerUseSelf = true;
                    }
                } else {
                    response.billingOwnerClientName = '';
                    response.billingOwnerClientId = null;
                    response.billingOwnerUseSelf = false;
                }

                return response;
            }
        },

        toJSON: function(options) {
            const model_json = Backbone.Model.prototype.toJSON.apply(this, arguments);
            if (typeof model_json.calculatedBeacons !== 'undefined') {
                delete model_json.calculatedBeacons;
            }
            if (typeof model_json.billingOwnerClientId !== 'undefined') {
                if (model_json.billingOwnerClientId != null) {
                    model_json.billingOwner = {
                        clientId: model_json.billingOwnerClientId
                    };
                }
                delete model_json.billingOwnerClientId;
            }
            if (typeof model_json.billingOwnerClientName !== 'undefined') {
                delete model_json.billingOwnerClientName;
            }
            if (typeof model_json.billingOwnerUseSelf !== 'undefined') {
                if (model_json.billingOwnerUseSelf) {
                    if (typeof model_json.id !== 'undefined') {
                        model_json.billingOwner = {
                            clientId: model_json.id
                        };
                    } else {
                        model_json.billingOwner = {
                            clientId: -1
                        };
                    }
                }
                delete model_json.billingOwnerUseSelf;
            }

            if (!_.isUndefined(model_json.type)) {
                model_json.ClientType = {
                    type: model_json.type
                };
                delete model_json.type;
            }

            if (_.isEmpty(model_json.maxBeacons)) {
                delete model_json.maxBeacons;
            }
            return model_json;
        },

        isDataSelling: function() {
            return this.id == CC.configData.LDNID;
        },

        isNetwork: function() {
            let is_network = false;
            const clientType = this.get('ClientType');
            if (
                typeof clientType !== 'undefined' &&
                typeof clientType.type !== 'undefined' &&
                clientType.type.toLowerCase() === 'network'
            ) {
                is_network = true;
            }
            return is_network;
        },

        replaceClientRelationships: function(replace) {
            if (replace) {
                this.setParam('replace_member_relationships', true);
            } else {
                this.unsetParam('replace_member_relationships');
            }
        },

        // Returns owning client if it can find it for a child client, else null.
        // This only works for a fully loaded client object.
        getOwner: function() {
            const self = this;
            let owner;
            const relationships = self.get('ClientRelationship');
            if (!_.isUndefined(relationships)) {
                const rel_len = relationships.length;
                for (let i = 0; i < rel_len; i++) {
                    const rel = relationships[i];
                    if (
                        !_.isUndefined(rel.Client) &&
                        !_.isUndefined(rel.owner) &&
                        rel.owner === true &&
                        !_.isUndefined(rel.relationshipType) &&
                        rel.relationshipType.toLowerCase() === 'parent'
                    ) {
                        owner = new this.constructor(rel.Client);
                        break;
                    }
                }
            }
            return owner;
        },

        mayBeDeactivated: function() {
            return this.id != CC.configData.LOTAMEID && this.id != CC.configData.LDNID;
        }
    });

    return client;
});
