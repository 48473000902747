define(function(require) {
    const ClientFeatureModel = require('models/security/ClientFeature.model');
    const CC = require('CC');
    const BaseCollection = require('models/Base.collection');

    const ClientFeaturesCollection = BaseCollection.extend({
        model: ClientFeatureModel,

        urlRoot: function() {
            return `${CC.apiUrl}/clients/{client_id}/features`;
        },

        setClientId: function(clientId) {
            this.setParam('client_id', clientId);
        },

        isEnabled: function(featureName) {
            return typeof this.findWhere({ name: featureName }) != 'undefined';
        },

        getIdForFeatureName: function(featureName) {
            const feature = this.findWhere({ name: featureName });
            if (typeof feature != 'undefined') {
                return feature.get('featureId');
            } else {
                return null;
            }
        }
    });

    return ClientFeaturesCollection;
});
