define(function(require) {
    const CC = require('CC');
    const BasicModel = require('models/app/BasicModel');

    const ClientCapability = BasicModel.extend({
        urlRoot: function() {
            return `${CC.apiUrl}/clients/{clientId}/capabilities/{capabilityName}`;
        },

        isEnabled: function() {
            return this.get('enabled');
        }
    });

    return ClientCapability;
});
