define(function(require) {
    const Client = require('models/client/Client');
    const _ = require('underscore');
    const Backbone = require('backbone');
    const CC = require('CC');

    var ClientList = Backbone.Collection.extend({
        model: Client,
        loading: false,
        loaded: false,
        addParent: false,
        comparator: 'name',

        initialize: function(models, options) {
            this.options = _.extend({}, options);
        },

        // generates base URL for typical use. HOWEVER, you may pass in a url attribute
        // on a fetch request to override this.
        url: function() {
            var url = CC.apiUrl + '/clients';
            if (this.options.queryParams) {
                return CC.utils.addQueryParams(url, this.options.queryParams);
            } else {
                return url;
            }
        },

        fetch: function(options) {
            this.loading = true;
            this.loaded = false;
            this.trigger('loading');
            var that = this;
            var request = Backbone.Collection.prototype.fetch.apply(this, arguments);
            request.done(function(data) {
                that.loading = false;
                that.loaded = true;
                that.trigger('loaded');
            });
            return request;
        },

        parse: function(response) {
            var data = [];
            if (typeof response !== 'undefined' && typeof response.clients !== 'undefined') {
                data = response.clients;
            } else if (typeof response !== 'undefined' && typeof response.Client !== 'undefined') {
                data = response.Client;
                // Add Parent as the top result
                if (this.options.addParent) {
                    if (
                        data[0] &&
                        data[0].ClientRelationship[0] &&
                        data[0].ClientRelationship[0].relationshipType === 'PARENT'
                    ) {
                        var parent = data[0].ClientRelationship[0].Client;
                        parent.name = parent.name + ' (Network)';
                        data.unshift(parent);
                    }
                }
            } else if (typeof response !== 'undefined' && Array.isArray(response)) {
                data = response;
            }
            return data;
        },

        isLoading: function() {
            return this.loading;
        },

        isLoaded: function() {
            return this.loaded;
        },

        setQueryParam: function(key, value) {
            this.options.queryParams[key] = value;
            return this;
        },

        removeQueryParam: function(key) {
            delete this.options.queryParams[key];
            return this;
        }
    });
    return ClientList;
});
