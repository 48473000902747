define(function(require) {
    const BasicModalView = require('app/modals/BasicModal.view');
    const _ = require('underscore');

    const basicModal = BasicModalView.extend(
        {
            defaults: _.extend({}, BasicModalView.prototype.defaults, {
                // The default element id for the modal
                modalId: 'confirmModal',
                // The text in the save button
                saveText: 'Yes, continue',
                // The text in the cancel button
                cancelText: 'Cancel',
                // The modal title
                modalTitle: 'Are you sure you want to continue?',
                // The main message in the modal body
                confirmMessage: 'You are about to do this action!',
                // Method to be called when the save button is pressed
                // Should be overridden
                confirmed: function() {},
                // Method to be called when the cancel button is pressed
                // Should be overridden
                cancelled: function() {},
                // Method to be called whenever modal is closed, regardless
                closed: function() {},
                // Method to be called on success from save after the modal has disappeared
                onSuccessExit: function() {},
                // The Save Action, typically an api request, should return the xhr
                // save: undefined,
                // Flag to show the confirmation state
                isConfirmed: false,
                // The Bootstrap class for the alert message
                alertType: 'default',
                // Style scheme to override the alertType; is ignored if undefined
                // scheme: undefined,
                // Delay to trigger finish
                finishDelay: 0,
                // Show the description from the ajax error response
                showDescription: false,
                // Render the error message in the footer as opposed to the bottom of the body
                showAlertInFooter: false,
                errorMessage: 'There was an error while trying to save'
            }),

            initialize: function(options) {
                BasicModalView.prototype.initialize.apply(this, arguments);

                this.adjustedAlertType = 'default';
                return this;
            },

            // Called by the Basic Modal render
            renderContentView: function() {
                const self = this;

                // Switch alertType to default if scheme is defined
                // This will allow the scheme styles to take priority

                if (_.isUndefined(self.contentView)) {
                    self.renderAlert({
                        alertType: self.adjustedAlertType,
                        message: self.confirmMessage,
                        forceSuccessRenderToElement: true,
                        el: `#${self.modalId}-content-holder`
                    });
                    self.openModal();
                } else {
                    BasicModalView.prototype.renderContentView.apply(self, arguments);
                    this.listenTo(this.contentView, 'error', xhr => {
                        this.processError(xhr);
                    });
                }
            },

            parseError: function(xhr, options) {
                let errorReason = 'System Error';
                const opts = _.extend(
                    {
                        showDescription: false
                    },
                    options
                );

                if (this.hasValue(xhr.responseText)) {
                    try {
                        const response = JSON.parse(xhr.responseText);
                        if (!_.isUndefined(response.message)) {
                            errorReason = response.message;
                        }
                        if (opts.showDescription && response.description) {
                            errorReason = `${errorReason} (${response.description})`;
                        }
                    } catch (e) {
                        errorReason = 'System Error';
                    }
                } else if (this.hasValue(xhr.responses)) {
                    errorReason = _.template(
                        '<% _.each(messages, function(message) { %> <p><%= message %></p> <% })%>'
                    )({
                        messages: xhr.responses
                    });
                }
                return errorReason;
            },

            // Called when the save/confirm button is pressed
            handleSave: function(event) {
                const self = this;
                let isSuccess = false;

                // Handle Content View Path
                let data;
                if (
                    (!_.isUndefined(self.contentView) && _.isFunction(self.contentView.save)) ||
                    !_.isUndefined(self.save)
                ) {
                    //
                    // Perform the Action
                    // Return Paths
                    // null: Do not close the modal; Reset the save button (eg. Validation Failed)
                    // true: Close the modal immediately; Sends return value to confirm handler
                    // promise: Wait until the promise is resolved (Close on success; Show Error message on failure)
                    try {
                        self.clearErrors();
                        if (!_.isUndefined(self.save)) {
                            data = self.save();
                        } else {
                            data = self.contentView.save();
                        }

                        if (data !== null) {
                            // Result was a promise
                            if (_.isFunction(data.fail)) {
                                data.fail(function(xhr, text, error) {
                                    self.processError(xhr);
                                    self.resetSaveButton();
                                }).done(function(response, textStatus, jqXHR) {
                                    isSuccess = textStatus == 'success' || self.isSuccessCode(jqXHR);
                                    self.isConfirmed = isSuccess;
                                    _.delay(function() {
                                        self.closeModal(isSuccess);
                                        self.confirmed.apply(self, [response]);
                                    }, self.finishDelay);
                                });
                                return data;
                            } else {
                                // Result was not a promise and potentially a return value
                                isSuccess = true;
                                self.isConfirmed = isSuccess;
                                self.confirmed.apply(self, [data]);
                                self.trigger('actionConfirmed');
                            }
                        }
                    } catch (error) {
                        const errorMessage = this.errorMessage;
                        self.renderErrorMessage(errorMessage);
                        self.resetSaveButton();
                        console.error(error);
                    }
                } else {
                    // Simple Confirm Path: No processing is done
                    isSuccess = true;

                    // Execute the confirmed callback
                    self.isConfirmed = isSuccess;
                    self.confirmed.apply(self, [data]);
                    self.trigger('actionConfirmed');
                }

                return isSuccess;
            },

            handleCancelClick: function() {
                const self = this;
                self.cancelled.apply(this);
            },

            handleCloseClick: function() {
                const self = this;
                self.cancelled.apply(this);
            },

            handleClose: function() {
                const self = this;
                if (self.isConfirmed) {
                    self.onSuccessExit();
                }
                self.closed(self.isConfirmed);
            },

            isSuccessCode: function(jqXHR) {
                return jqXHR && jqXHR.status && jqXHR.status >= 200 && jqXHR.status < 300;
            },

            processError: function(xhr) {
                const errorReason = this.parseError(xhr, {
                    showDescription: true
                });
                let errorMessage = this.errorMessage;

                if (errorReason !== '') {
                    errorMessage = `${errorMessage} due to : <em>${errorReason}</em>`;
                }

                this.renderErrorMessage(errorMessage);
            }
        },
        {
            WAIT_FOR_ES_DELAY: 2500
        }
    );
    return basicModal;
});
