define(function(require) {
    const CurrentClientSwitcherTemplate = require('./CurrentClientSwitcher.template.html');
    const BaseView = require('app/Base.view');
    const typeAheadNoResultsTemplate = require('./typeAheadNoResults.template.html');
    const $ = require('jquery');
    const _ = require('underscore');
    const Backbone = require('backbone');
    let CC = require('CC');
    require('typeAhead');
    require('./CurrentClientSwitcher.css');

    const CurrentClientSwitcherView = BaseView.extend({
        template: _.template(CurrentClientSwitcherTemplate),
        typeAheadResultsTemplate: _.template(`
        <div class="typeAheadResult">
            <div class="typeAheadResultContent">		
                <div class="typeAheadName inline-block"><%- name %></div> 		
                <% if(value) { %> 
                    <span class="type-ahead-holder">[<span class="typeAheadValue"><%- value %></span>]</span>
                <% } %>                
            </div>           
        </div>
        `),
        typeAheadNoResultsTemplate: _.template(typeAheadNoResultsTemplate),

        defaults: function() {
            return {
                isAdminUser: CC.authorizeByPermission('Administration')
            };
        },

        events: {
            'click .assignedClient': 'handleClick'
        },

        handleClick: function(e) {
            const self = this;
            const clientId = self.$(e.currentTarget).attr('data-client-id');
            const currentClient = CC.SessionData.userOrgs.get(clientId);
            self.model.set('currentClient', currentClient);
        },

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults(), options);
        },

        render: function() {
            const self = this;
            //
            // FIXME: Working around the cyclical dependency we have
            //  with CC and the navigation menu
            //
            if (typeof CC === 'undefined') {
                CC = require('CC');
            }
            const viewOptions = {
                clientRoleSet: _.sortBy(CC.SessionData.userInfo.get('clientRoleSet'), function(role) {
                    return role.client.name;
                }),
                currentId: CC.utils.getCurrentClientId().toString(),
                memberClientId: CC.SessionData.userInfo.get('memberClientId'),
                isAdminUser: self.isAdminUser
            };

            self.model = new Backbone.Model({
                currentClientId: 0
            });
            self.listenTo(self.model, 'change:currentClient', self.handleChange);

            self.$el.html(this.template(viewOptions));

            if (self.isAdminUser) {
                self.renderSearch();
            }

            return self;
        },

        renderSearch: function() {
            const self = this;
            const clientBloodhound = new Bloodhound({
                datumTokenizer: function(datum) {
                    // eslint-disable-next-line no-undef
                    return Bloodhound.tokenizers.whitespace(datum.value);
                },
                limit: 100,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: `${CC.apiUrl}/clients/permission?sort_attr=name&sort_order=ASC&include_child_clients=true&permission_name=View%20Reports&audience_ops=false&filter=%QUERY&page_count=100`,
                    filter: function(response) {
                        return _.map(response.Client, function(clientObj) {
                            return {
                                value: clientObj.id,
                                name: clientObj.name,
                                model: new Backbone.Model(clientObj)
                            };
                        });
                    }
                }
            });
            clientBloodhound.initialize();
            const clientSearch = this.$('#clientSearchBox');
            clientSearch.typeahead(
                {
                    hint: false,
                    highlight: true,
                    minLength: 1
                },
                [
                    {
                        name: 'clientSearch',
                        displayKey: 'value',
                        source: clientBloodhound.ttAdapter(),
                        templates: {
                            header: ['<div class="suggestionsHeader">Clients</div>'].join('\n'),
                            empty: self.typeAheadNoResultsTemplate(),
                            suggestion: function(data) {
                                return self.typeAheadResultsTemplate({
                                    name: data.name,
                                    value: data.value
                                });
                            }
                        }
                    }
                ]
            );

            clientSearch.on('typeahead:selected', function(evtObj, suggestionObj, dataSetName) {
                CC.utils.trackEvent(
                    'ClientSwitcher',
                    'NavBar - ClientSwitcher',
                    `${suggestionObj.category}:${suggestionObj.value}`
                );
                self.model.set('currentClient', suggestionObj.model);
            });
            this.$('#clientSearchBox').trigger('focus');
        },

        handleChange: function() {
            const self = this;
            self.trigger('save');
        },

        save: function() {
            const self = this;
            const currentClient = self.model.get('currentClient');
            // Clear any whitelabel reference
            CC.utils.storeWhiteLabelKey(undefined);

            const errorMessage = CC.utils.setCurrentClientInfo(currentClient);

            const defer = $.Deferred();
            if (!currentClient) {
                return null;
            }
            if (errorMessage) {
                defer.reject({
                    responseText: JSON.stringify({ message: errorMessage })
                });
            } else {
                self.renderAlert({
                    el: '#messageHolder',
                    message: `Switching the current client to be <strong>${currentClient.get(
                        'name'
                    )}</strong>. This may take a moment...`,
                    alertType: 'info'
                });

                self.listenToOnce(CC.Events, 'session_loaded', function() {
                    defer.resolve();
                });
                self.listenToOnce(CC.Events, 'session_error', function() {
                    defer.reject();
                });

                CC.Events.trigger('CurrentClientInfo:Changed', currentClient);
            }

            return defer;
        }
    });

    return CurrentClientSwitcherView;
});
