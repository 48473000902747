define(function(require) {
    const CC = require('CC');
    const BasicModel = require('models/app/BasicModel');
    return BasicModel.extend({
        idAttribute: 'featureId',

        urlRoot: function() {
            return `${CC.apiUrl}/clients/{client_id}/features`;
        },
        toJSON: function(options) {
            const modelJson = BasicModel.prototype.toJSON.apply(this, arguments);
            delete modelJson.enabled;
            return modelJson;
        }
    });
});
