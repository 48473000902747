define(['jquery', 'underscore', 'backbone', 'CC', 'models/security/UserSetting'], function(
    $,
    _,
    Backbone,
    CC,
    UserSetting
) {
    const userSettings = Backbone.Collection.extend({
        model: UserSetting,

        url: function() {
            return `${CC.apiUrl}/users/activeUser/settingList`;
        },

        //defines how to return the response from the api server to the model
        parse: function(response) {
            let parsedResponse = response;
            if (typeof response.settings !== 'undefined') {
                const settings = response.settings;
                parsedResponse = [];
                if ($.isArray(settings)) {
                    const settingslength = settings.length;
                    for (let index = 0; index < settingslength; index++) {
                        const mykey = settings[index]['key'];
                        let myValue;
                        try {
                            // parse it if possible
                            myValue = JSON.parse(settings[index]['value']);
                        } catch (e) {
                            // if cannot parse, probably a 'raw' string
                            myValue = settings[index]['value'];
                        }
                        const setting = new UserSetting({
                            key: mykey,
                            value: myValue
                        });
                        parsedResponse.push(setting);
                    }
                }
            }
            return parsedResponse;
        },

        // wrapper method to find setting by the key.
        // Done this way so that if internals change, consumer won't be affected
        getByKey: function(key) {
            return this.findWhere({ key: key });
        }
    });

    return userSettings;
});
